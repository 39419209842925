import React, { useState } from 'react';
import RegistrationComponent from './RegistrationComponent';
import LoginComponent from './LoginComponent';

const AuthComponent = ({close}: {close: Function}) => {
  const [authType, setAuthType] = useState('login');

  return <div className="pt-4 pb-4">
    <div className='flex gap-2 justify-center text-lg'>
      <div className={'cursor-pointer' + (authType === 'login' ? '' : ' underline text-light-blue')} onClick={() => setAuthType('login')}>Login</div>
      <div>|</div>
      <div className={'cursor-pointer' + (authType === 'register' ? '' : ' underline text-light-blue')} onClick={() => setAuthType('register')}>Register</div>
    </div>
    {authType === 'login' ? <LoginComponent finished={close} /> : <RegistrationComponent finished={close} />}
  </div>
}

export default AuthComponent;
