import React, { useEffect, useState } from 'react';
import { TestedBeerInterface } from '../models/TestedBeerModel';
import { ApiRequestProps, useAPI } from '../hooks/useAPI';
import Logo from '../assets/logo-bw.svg';

const TestedBeerView = ({beerId}: {beerId: number}) => {
    const [request, setRequest] = useState<ApiRequestProps | null>(null);
    const {data: beerData} = useAPI<TestedBeerInterface>(request);

    useEffect(() => {
        if (typeof beerId === 'undefined') return;
        setRequest({
            method: 'get', 
            path: `${process.env.REACT_APP_API_URL}/beer/tested/${beerId}`, 
            params: {'include': ['FileReference', 'BeerStyle', 'Brewery']}});
    }, [beerId]);

    return (
        <div className=''>
            {beerData ? <div>
                <div className='flex flex-row flex-wrap'>
                    <div className='basis-1/3 h-[200px]'>
                        {beerData.image ? 
                            <img className='h-full w-full object-contain object-center' src={`${process.env.REACT_APP_API_URL}/file/private-file/${beerData.imageId}`} /> :
                            <div className='bg-medium-blue h-full flex justify-center items-center'>
                                <img className='h-[120px]' src={Logo} alt='no-label' />
                            </div>
                        }
                    </div>
                    <div className='basis-2/4'>
                        <div>{beerData.name}</div>
                        <div>{beerData.style?.name}</div>
                        <div>{beerData.brewery?.name}</div>
                        <div>{beerData.brewery?.country}</div>
                        <div>{beerData.abv}</div>
                        <div>{beerData.ibu}</div>
                    </div>
                </div>
                <div>{beerData.description}</div>
            </div> : null}
        </div>
    );
}

export default TestedBeerView;
