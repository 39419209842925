import React, { useState } from 'react';
import Axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import BeerButton from '../BeerButton';

const RegistrationComponent = ({finished}: {finished: Function}) => {
  // const { token } = useContext(AuthContext);
  const { login } = useAuth();
  const [signupForm, setSignupForm] = useState({
    login: '',
    password: '',
    name: ''
  });

  const submit = () => {
    Axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, signupForm).then(
      res => {
        const authToken = res.data.token;
        login(authToken);
        finished();
      },
      rej => { }
    )
  }

  return (
    <div className="px-4 mx-auto mt-6">
      {/* <div className='text-2xl text-center mb-10'>Sign up</div> */}
      <div className='w-full'>
        <label>Login</label>
        <input className='block w-full border rounded-sm h-[36px] mt-2 mb-6 px-3' value={signupForm.login} onChange={event => {
          const value = event.target.value;
          setSignupForm(current => ({ ...current, login: value }))
        }} />
      </div>
      <div className='w-full'>
        <label>Password</label>
        <input type='password' className='block w-full border rounded-sm h-[36px] mt-2 mb-6 px-3' value={signupForm.password} onChange={event => {
          const value = event.target.value;
          setSignupForm(current => ({ ...current, password: value }))
        }} />
      </div>
      <div className='w-full'>
        <label>Name</label>
        <input className='block w-full border rounded-sm h-[36px] mt-2 mb-6 px-3' value={signupForm.name} onChange={event => {
          const value = event.target.value;
          setSignupForm(current => ({ ...current, name: value }))
        }} />
      </div>
      <div className='text-center'>
        <BeerButton text='Submit' onClick={submit} />
      </div>
    </div>
  );
}

export default RegistrationComponent;
