import React, { useContext, useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from '../../context/authContext';
import Account from '../../assets/account.svg';
import Logout from '../../assets/logout.svg';
import Logo from '../../assets/logo-bw.svg';
import AuthComponent from '../auth/AuthComponent';
import ModalListContext from '../../context/modalListContext';
import UserAvatar from '../UserAvatar';

const publicMenu = [
  { name: 'Styles', path: '/styles' },
  { name: 'Breweries', path: '/breweries' },
  { name: 'Beers', path: '/beers' }
];
const privateMenu = [
  { name: 'Styles', path: '/styles' },
  { name: 'Breweries', path: '/breweries' },
  { name: 'Beers', path: '/beers' },
  { name: 'My beers', path: '/my-tested' }
];

const Header = () => {
  const router = useHistory();
  
  const { token, user } = useContext(AuthContext);
  const { logout } = useAuth();
  // const location = useLocation();
  const menuItems = token ? privateMenu : publicMenu;
  const {addModal, closeModal} = useContext<any>(ModalListContext);
  const [menuOpened, setMenuOpened] = useState(false);

  const openLogin = (event: any) => {
    const id = String(Math.random());
    addModal({
        content: <AuthComponent 
            close={() => closeModal(id)}
        ></AuthComponent>,
        size: {
          maxWidth: 320,
          maxHeight: 'fit-content'
        }
    });
  }

  const toAccount = (userId: number) => {
    router.push('/account/profile');
  }

  return (
    <div className='bg-dark-blue h-[60px] lg:h-[80px] w-full fixed top-0 z-40 flex items-center justify-between px-3'>
      <div className='flex items-center w-[100px]'>
        <img src={Logo} className='h-[45px] lg:h-[65px] mr-4' alt='logo' />
        <span className='font-bold text-2xl lg:text-3xl text-white'>BeerIt</span>
      </div>
      <nav className='hidden lg:flex container max-w-6xl w-full justify-center mx-auto'>
        {menuItems.map((item, index) => <NavLink activeClassName='text-dark-yellow' key={'menu-item-' + index}
          className='px-4 lg:px-6 text-white uppercase font-semibold text-lg'
          to={item.path}>
          {item.name}
        </NavLink>)}
      </nav>
      <div className='h-[40px] flex justify-end items-center w-[100px]'>
        {!token ? <img src={Account} className='h-7 cursor-pointer' onClick={openLogin} alt='account' /> : null}
        {token ? <div className='flex items-center gap-3'>
          {user ? <UserAvatar userData={user} size={36} onClick={toAccount} /> : null}
          <img src={Logout} className='cursor-pointer h-7' onClick={logout} alt='logout' />
        </div> : null}
        <div className='block ml-2 lg:hidden bg-transparent h-[28px] w-[40px] relative box-content p-0' onClick={() => setMenuOpened(!menuOpened)}>
          <span className={`${menuOpened ? 'top-[14px] rotate-[45deg] ' : 'top-[7px] '}
          h-[2px] w-[22px] block absolute 
          origin-center left-[10px] bg-white transition-all 
          duration-300 ease-in-out`}></span>
          <span className={`${menuOpened ? 'top-[14px] rotate-[-45deg] ' : 'top-[13px] '}
          h-[2px] w-[22px] block absolute origin-center left-[10px]
          bg-white transition-all duration-300 ease-in-out`}></span>
          <span className={`${menuOpened ? 'left-[27px] w-0 ' : 'w-[22px] '}
          h-[2px] block absolute origin-center top-[19px] left-[10px] 
          bg-white transition-all duration-300 ease-in-out`}></span>
        </div>
      </div>
      <div className='lg:hidden w-full fixed top-[60px] bg-dark-blue -ml-3 transition-all duration-200 ease-in overflow-hidden' style={{maxHeight: menuOpened ? '300px' : '0px'}}>
        <nav className='w-full flex-col flex items-center'>
          {menuItems.map((item, index) => <NavLink activeClassName='text-dark-yellow' key={'menu-item-' + index}
            className='px-4 lg:px-6 text-white uppercase font-semibold text-lg py-2 last:pb-6 first:pt-6'
            to={item.path} onClick={() => setMenuOpened(false)}>
            {item.name}
          </NavLink>)}
        </nav>
      </div>
    </div>
  );
}

export default Header;
