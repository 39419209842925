import React, { useEffect, useState } from 'react';
import Right from '../../assets/chevron_right.svg';
import Left from '../../assets/chevron_left.svg';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    visiblePages?: number;
    changed: Function;
    className: string;
}

interface PageItem {
    num: number;
    isHiddenSection?: boolean;
}

const Pagination = ({currentPage=1, totalPages=0, visiblePages=5, changed, className}: PaginationProps) => {
    const [pages, setPages] = useState<PageItem[]>([{num: 1}]);
    
    useEffect(() => {
        console.log(pages);
    }, [pages]);

    useEffect(() => {
        console.log('page', currentPage, totalPages);
        if (totalPages <= visiblePages) {
            setPages(new Array(totalPages).fill(1).map((v, i) => ({num: v+i})));
            console.log(new Array(totalPages).fill(1).map((v, i) => ({num: v+i})));
        } else {
            const centerLength = visiblePages - 2;
            const centerPages = new Array(centerLength).fill(0).map((v, i) => v + i);
            let centerStart = currentPage - Math.floor(centerLength / 2);
            if (centerStart < 1) centerStart = 1;
            let centerEnd = centerStart + (centerLength - 1);
            if (centerEnd > totalPages) {
                centerStart -= centerEnd - totalPages;
                centerEnd = centerStart + (centerLength - 1);
            }
            let newPages = [
                ...(centerStart === 1 ? [] : [{num: 1}]), 
                ...(centerStart <= 2 ? [] : [{num: 0, isHiddenSection: true}]),
                ...centerPages.map(v => ({num: v + centerStart})),
                ...(centerEnd + 1 >= totalPages ? [] : [{num: 0, isHiddenSection: true}]),
                ...(centerEnd === totalPages ? [] : [{num: totalPages}])
            ];
            setPages(newPages);
        }
    }, [currentPage, totalPages, visiblePages])

    const selected = (page: PageItem) => {
        if (!page.isHiddenSection && page.num !== currentPage) {
            changed(page.num);
        }
    }

    const prev = () => {
        if (currentPage > 1) selected({num: currentPage - 1});
    }

    const next = () => {
        if (currentPage < totalPages) selected({num: currentPage + 1});
    }

    return (
        <div className={'flex justify-center mx-auto ' + className}>
            <div className={'h-[36px] w-[36px] rounded-full text-light-blue flex items-center justify-center leading-[34px] border select-none' + 
                ' border-transparent hover:border-pale-blue cursor-pointer'} onClick={prev}>
                    <img className='h-[20px]' src={Left} />
            </div>
            {pages.map(
                (page, i) => <div
                    className={'h-[36px] w-[36px] mx-[2px] font-normal rounded-full text-light-blue text-center leading-[34px] border select-none' + 
                        (page.isHiddenSection ? ' border-transparent' : ' hover:border-pale-blue cursor-pointer') +
                        (page.num === currentPage ? ' border-light-blue font-semibold' : ' border-transparent')
                    } key={'page-' + i}
                    onClick={() => selected(page)}
                >{page.isHiddenSection ? '...' : page.num}
                </div>
            )}
            <div className={'h-[36px] w-[36px] rounded-full text-light-blue flex items-center justify-center leading-[34px] border select-none' + 
                ' border-transparent hover:border-pale-blue cursor-pointer'} onClick={next}>
                    <img className='h-[20px]' src={Right} />
            </div>
        </div>
    );
}

export default Pagination;
