import React, { useEffect, useState, useContext } from 'react';
import TestedBeerForm from '../components/forms/TestedBeerForm';
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

const NewTestedBeer = () => {
    const router = useHistory();

    const saved = () => {
        router.push('/my-tested');
    }

    return (
        <div className='container pt-[50px] max-w-6xl'>
            <PageHeader title='Create new tested beer' />
            <TestedBeerForm saved={saved} />
        </div>
    );
}

export default NewTestedBeer;
