import React, { useEffect, useState } from 'react';
import EmptyStar from '../assets/star_empty.svg';
import Star from '../assets/star.svg';

export type UserAvatarData = {
    name: string,
    image: {url: string},
    id: number
}

const UserAvatar = ({userData, size=46, onClick}: {userData: UserAvatarData, size?: number, onClick?: Function}) => {
    let initials = '';
    if (!userData.image && userData.name) {
        const parts = userData.name.split(' ');
        initials = parts.map(v => v.slice(0, 1)).join('').slice(0, 2);
    }

    const clicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        if (onClick && typeof onClick === 'function') {
            onClick(userData.id);
        }
    }

    return (
        <div onClick={clicked} style={{width: size, height: size}} className={`flex justify-center items-center rounded-full bg-light-blue border border-warm-yellow`}>
            {userData.image ?
                <img src={userData.image?.url} className='w-full h-full object-cover rounded-full' /> : 
                <span className='text-xl capitalize text-white'>{initials}</span>
            }
        </div>
    );
}

export default UserAvatar;
