import React, { useContext } from 'react';
import BeersContext from '../../context/beersContext';
import { TestedBeerInterface } from '../../models/TestedBeerModel';

const BeerInfo = () => {
    const beer = useContext<TestedBeerInterface | null>(BeersContext);

    return beer ? (
        <>                
            <div className={`uppercase text-xl px-8 mb-4 font-bold h-[68px]
             bg-dark-blue flex text-center justify-center items-center text-white`}>{beer.name}</div>
            <div className='text-center px-8'>
                <div className='italic'>{beer.brewery?.name}</div>
                <b>{beer.style?.name}</b>
                <div className='flex justify-between'>
                    <div>ALC: {beer.abv}%</div>
                    <div>IBU: {beer.ibu}</div>
                </div>
            </div>
        </>
    ) : null;
}

export default BeerInfo;
