import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { routes } from "../../routing/routes";
import Header from './Header';
import { PrivateRoute } from '../../routing/PrivateRoute';

const MainLayout = () => {

  return (
    <div className='bg-warm-yellow min-h-[100vh]'>
      <Header></Header>
      <div className={`pt-[60px] lg:pt-[80px]`}>
        <Switch>
          {routes.map((route, key) => route.private ? <PrivateRoute
            path={route.path}
            component={route.component}
            key={'route-' + key}
          /> : <Route
            path={route.path}
            render={props => (<route.component {...props} />)}
            key={'route-' + key}
          />
          )}
          <Redirect to="/beers" />
        </Switch>
      </div>
    </div>
  );
}

export default MainLayout;
