import React from 'react';
import { useHistory } from 'react-router-dom';
import BeersList from '../components/BeersList';
import BeerButton from '../components/BeerButton';

const MyTestedBeers = () => {
  const router = useHistory();

  const newBeer = () => {
    router.push('/my-tested/new');
  }

  return (
    <BeersList onClick={newBeer} renderEmpty={() =>
      <div className='w-full h-[200px] flex flex-col justify-center items-center'>
        <div className='mb-3 text-lg'>You do not have reviews yet. Let`s fix it!</div>
        <BeerButton text='CREATE NEW REVIEW' onClick={newBeer} />
      </div>
     } title='MY TESTED BEERS' path='/beer/tested/user/list' />
  );
}

export default MyTestedBeers;
