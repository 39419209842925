import React, { useContext } from 'react';
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from '../context/authContext';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { token, isLoading } = useContext(AuthContext);
  if (isLoading) return <div></div>;

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/beer",
            }}
          />
        )
      }
    />
  );
}