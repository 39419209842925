import { useCallback, useContext, useEffect, useState } from "react";
import Axios from "axios";
import { AuthContext } from "../context/authContext";
import jwtDecode from "jwt-decode";
import { UserJwtPayload, useAuth } from "./useAuth";
import moment from "moment";

export interface ApiRequestProps {
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  body?: any,
  params?: any
}

export const useAPI = <T>(request: ApiRequestProps | null) => {
  const { token } = useContext(AuthContext);

  const { refreshToken } = useAuth();
  const [data, setdata] = useState<T | null>(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (!request) return;
    const payload = {
      url: request.path,
      method: request.method,
      baseURL: process.env.REACT_APP_API_URL,
      params: request.params ? request.params : {},
      data: request.body ? request.body : undefined
    }
    if (token) {
      const decodedToken = jwtDecode<UserJwtPayload>(token);
      const timeBuffer = 60 * 60 * 2; // two hours;
      if (decodedToken.exp && ((moment().unix() + timeBuffer) > decodedToken.exp)) {
        refreshToken(token);
      }
    }
    const makeRequest = (payload: any) => {
      Axios({
        ...payload,
        headers: token ? { Authorization: `Bearer ${token}` } : {}
      })
        .then((data) => {
          // seterror(data.error)
          setdata(data.data)
          setloading(false)
        })
    };
    makeRequest(payload);
  }, [request]);

  return { data, loading };
};