import React, { useState } from 'react';
import BeersContext from '../context/beersContext';
import BeerCard from '../components/BeerCard/BeerCard';
import Rating from '../components/Rating';
import { ApiRequestProps, useAPI } from '../hooks/useAPI';
import PageHeader from '../components/PageHeader';
import BeerSearch, { SearchData } from '../components/BeerSearch';
import { TestedBeerInterface } from '../models/TestedBeerModel';
import Pagination from './listUtils/Pagination';

const BeersList = ({
    onClick, 
    perPage = 12, 
    path='/beer/tested/list', 
    title='FIND YOUR FAVOURITE', 
    renderEmpty=() => null
}: {
    onClick: Function, 
    perPage?: number, 
    path?: string, 
    title?: string,
    renderEmpty?: Function
}) => {
  const [request, setRequest] = useState<ApiRequestProps>({
    method: 'get',
    path: path,
    params: {
      sort: [['id', 'DESC']],
      pagination: {
        page: 0,
        perPage: perPage
      }
    }
  });
  const { data } = useAPI<{ totalItems: number, items: TestedBeerInterface[] }>(request);
  const beers = data ? data.items : [];
  const totalPages = data?.totalItems ? Math.ceil(data?.totalItems / request.params.pagination.perPage) : 0;

  const refresh = () => {
    setRequest({...request});
  }

  const pageChanged = (pageNum: number) => {
    if (pageNum !== request.params.pagination.page) {
      setRequest({ ...request, params: { ...request.params, pagination: { ...request.params.pagination, page: pageNum } } });
    }
  }

  const searchChanged = (searchData: SearchData) => {
    const searchQuery: any = { ...searchData };
    if (searchQuery.breweryId === -1 || searchQuery.breweryId === '-1') {
      delete searchQuery.breweryId;
    }
    if (searchQuery.styleId === -1 || searchQuery.styleId === '-1') {
      delete searchQuery.styleId;
    }
    if (searchQuery.name.trim() === '') {
      delete searchQuery.name;
    } else {
      searchQuery.name = { $like: `%${searchQuery.name.trim()}%` }
    }
    if (searchQuery.rating > 0) {
      searchQuery.rating = { $gte: searchQuery.rating };
    } else {
      delete searchQuery.rating;
    }
    setRequest({
      ...request,
      params: {
        ...request.params,
        pagination: { page: 0, perPage: perPage },
        where: searchQuery
      }
    })
  }

  const beerClicked = (beer: TestedBeerInterface) => {
    if (onClick) {
        onClick(beer);
    }
  }

  return (
    <div className='w-full mx-auto'>
      <div className='bg-[url("../public/hopbg-1.jpg")] bg-contain pt-[50px] mb-8'>
        <div className='container max-w-6xl mx-auto'>
          <PageHeader title={title} color='white' />
          <div className='px-10 pb-10'>
            <BeerSearch searchChanged={searchChanged} />
          </div>
        </div>
      </div>
      <div className={`${beers && beers.length > 0 ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' : ''} container max-w-6xl mx-auto justify-items-center gap-y-8 pb-10`}>
        {beers && beers.length > 0 ? beers.map((beer: any, index: number) => <BeersContext.Provider key={'beerCard' + index} value={beer}>
          <BeerCard refreshList={refresh} onClick={beerClicked}>
            <BeerCard.BeerImage></BeerCard.BeerImage>
            <BeerCard.BeerInfo></BeerCard.BeerInfo>
            <BeerCard.BeerDescription></BeerCard.BeerDescription>
            <div className='flex justify-center'><Rating value={beer.rating} immutable={true} /></div>
          </BeerCard>
        </BeersContext.Provider>) : renderEmpty()}
      </div>
      <Pagination className='pb-10' currentPage={request.params.pagination.page + 1}
        totalPages={totalPages} changed={(num: number) => pageChanged(num - 1)} />
    </div>
  );
}

export default BeersList;
