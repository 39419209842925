import React, { MouseEventHandler } from 'react';
import { BeerStyle } from '../models/BeerStyle.model';
import VisibilityOff from '../assets/visibility_off.svg';

const StyleCard = ({styleData, fullView, onClick}: {styleData: BeerStyle, fullView?: boolean, onClick?: MouseEventHandler<HTMLDivElement>}) => {
    return (
        <div onClick={onClick ? onClick : () => {}} className={`${fullView ? 'w-full h-fit-content' : 'w-[150px] rounded'} overflow-hidden 
        relative shadow-gray-300 shadow-lg text-black-blue bg-lightest-yellow cursor-pointer`}>
            <div className={`py-1 bg-medium-blue text-white min-h-[50px] px-2 flex text-center justify-center items-center ${fullView ? 'text-lg flex items-center justify-center' : 'leading-[1.1]'}`}>{styleData.name}</div>
            <div className={`text-sm mt-2 ${fullView ? 'px-5' : 'px-3'}`}>ABV: <span>{styleData.minABV}%-{styleData.maxABV}%</span></div>
            <div className={`text-sm mb-2 ${fullView ? 'px-5' : 'px-3'}`}>IBU: <span>{styleData.minIBU}-{styleData.maxIBU}</span></div>
            <div className={`text-[.8rem] italic px-3 text-justify text-gray-800 leading-[1.2] ${
                fullView ? 'h-fit-content px-5 pb-5' : 'min-h-[130px]'}`}>{fullView ? styleData.description : styleData.shortDescription + '...'}</div>
        
            {styleData.moderated ? null : <div className={`absolute top-0 right-[15px] w-7 h-8
                bg-red-600 opacity-80 flex justify-center items-center rounded-b-md`}
                data-tooltip-id="unmoderated-tip" data-tooltip-variant='light'
                data-tooltip-html="This beer style is unmoderated, it is visible only to you.<br>We will review it as soon as we can.">
                <img className='w-4' src={VisibilityOff} />
            </div>}
        </div>
    );
}

export default StyleCard;
