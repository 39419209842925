import React, { useContext, useEffect } from 'react';
import { TestedBeerInterface } from '../models/TestedBeerModel';
import ModalListContext from '../context/modalListContext';
import TestedBeerView from '../components/TestedBeerView';
import BeersList from '../components/BeersList';

const TestedBeers = () => {
  const { addModal } = useContext<any>(ModalListContext);
  const openBeer = (beer: TestedBeerInterface) => {
    if (!beer) return;

    addModal({
      content: <TestedBeerView beerId={beer.id}
      ></TestedBeerView>,
      size: {
        maxWidth: '960px',
        maxHeight: 'fit-content'
      }
    });
  }

  useEffect(() => {
    console.log('loading gen comp');
  }, [])
  
  return (
    <BeersList onClick={openBeer} />
  );
}

export default TestedBeers;
