import React, { MouseEventHandler, ReactNode, useContext } from 'react';
import BeerDescription from './BeerDescription';
import BeerInfo from './BeerInfo';
import BeerImage from './BeerImage';
import BeersContext from '../../context/beersContext';
import Axios from 'axios';
import { TestedBeerInterface } from '../../models/TestedBeerModel';
import Edit from '../../assets/edit.svg';
import ModalListContext from '../../context/modalListContext';
import TestedBeerForm from '../forms/TestedBeerForm';
import { AuthContext } from '../../context/authContext';
import { useHistory } from 'react-router-dom';

const BeerCard = ({refreshList, children, onClick}: {refreshList: Function, children: ReactNode[], onClick?: Function}) => {
    const beer = useContext<TestedBeerInterface | null>(BeersContext);
    const { user } = useContext(AuthContext);
    const router = useHistory();

    const openBeer = () => {
        if (beer) {
            console.log(beer.name);
        }
    }

    const deleteBeer = () => {
        if (beer && beer.id >= 0) {
            Axios.delete(`${process.env.REACT_APP_API_URL}/beer/${beer.id}`).then(
                res => refreshList(),
                rej => {}
            )
        }
    }

    const openEditor = (event: any) => {
        if (!beer) return;
        router.push(`/my-tested/edit/${beer.id}`);
    }

    const clicked = () => {
        if (onClick) {
            onClick(beer);
        }
    }

    return (
        <div className={`w-[300px] xl:w-[270px] bg-white rounded-xl overflow-hidden 
        hover:cursor-pointer relative pb-6 shadow-gray-300 shadow-lg text-black-blue`} onClick={clicked}>
            {user?.id === beer?.userId ? <div className='absolute bg-gradient-to-b from-dark-blue to-transparent to-90% w-full h-[80px] top-0 left-0 z-10'></div> : null}
            {user?.id === beer?.userId ? <div className='absolute top-2 right-1 text-3xl h-12 w-12 z-20'>
                <div className='text-white text-center' onClick={deleteBeer}>&times;</div>
            </div> : null}
            {user?.id === beer?.userId ? <div className='absolute top-4 left-4 z-20' onClick={openEditor}><img className='h-[28px]' src={Edit} alt='edit' /></div> : null }
            {children}
        </div>
    );
}

BeerCard.BeerDescription = BeerDescription;
BeerCard.BeerInfo = BeerInfo;
BeerCard.BeerImage = BeerImage;

export default BeerCard;
