import React, { useContext } from 'react';
import BeersContext from '../../context/beersContext';
import { TestedBeerInterface } from '../../models/TestedBeerModel';
import Logo from '../../assets/logo-bw.svg';
import UserAvatar from '../UserAvatar';
import { useHistory } from 'react-router-dom';

const BeerImage = () => {
    const router = useHistory();
    const beer = useContext<TestedBeerInterface | null>(BeersContext);
    const toUserBeers = (userId: number) => {
        router.push(`/beers/user/${userId}`);
    }
    console.log(beer);

    return beer ? (
        <div className='w-[full] h-[215px] xl:h-[200px] relative'>
            {beer.image ? 
            <img className='w-full h-full object-cover object-center' alt='beer-label'
            // src={beer.image.url} 
            src={`${process.env.REACT_APP_API_URL}/file/private-file/${beer.imageId}`}
            /> : <div className='bg-medium-blue h-full flex justify-center items-center'>
                <img className='h-[120px]' src={Logo} alt='no-label' />
            </div>}
            <div className='absolute right-3 -bottom-[23px]'><UserAvatar userData={beer.user} onClick={toUserBeers} /></div>
        </div>
    ) : null;
}

export default BeerImage;
