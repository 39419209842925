import Axios from 'axios';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { TestedBeerInterface } from '../../models/TestedBeerModel';
import { BeerStyle } from '../../models/BeerStyle.model';
import { Brewery } from '../../models/Brewery.model';
import Rating from '../Rating';
import BeerButton from '../BeerButton';
import { ApiRequestProps, useAPI } from '../../hooks/useAPI';
import Logo from '../../assets/logo-bw.svg';
import { countryCodes } from '../../data/countries';
import { AuthContext } from '../../context/authContext';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';

interface BeerFormInput {
  name: string,
  breweryId: number,
  ibu: number,
  abv: number,
  styleId: number,
  rating: number,
  image: any | null,
  description: string,
  breweryCountry?: string,
  breweryName?: string
}

const TestedBeerForm = ({ beerData, saved }: { beerData?: TestedBeerInterface, saved: Function }) => {
  const {token} = useContext(AuthContext);
  const [loadedImage, setLoadedImage] = useState<{id: number, url: string} | null>(beerData && beerData?.image ? beerData.image : null);
  const [imagePreview, setImagePreview] = useState(beerData && beerData.image ? beerData.image.url : null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    getValues
  } = useForm<BeerFormInput>({
    defaultValues: {
      name: beerData && beerData.name ? beerData.name : '',
      breweryId: beerData && beerData.breweryId ? beerData.breweryId : 0,
      ibu: beerData && beerData.ibu ? beerData.ibu : undefined,
      abv: beerData && beerData.abv ? beerData.abv : undefined,
      styleId: beerData && beerData.styleId ? beerData.styleId : 0,
      rating: beerData && beerData.rating ? beerData.rating : 0,
      image: beerData && beerData.image ? beerData.image : undefined,
      description: beerData && beerData.description ? beerData.description : ''
    }
  });

  const [saveRequest, setSaveRequest] = useState<ApiRequestProps | null>(null);
  const { data: savedData } = useAPI(saveRequest);
  
  const [breweriesRequest] = useState<ApiRequestProps | null>({method: 'get', path: `${process.env.REACT_APP_API_URL}/entity/Brewery/list`});
  const { data: breweriesData } = useAPI<{items: Brewery[]}>(breweriesRequest);
  const breweries = breweriesData ? breweriesData.items.map(v => ({value: v.id, label: v.name})) : [];

  const [stylesRequest] = useState<ApiRequestProps | null>({
    method: 'get', 
    path: `${process.env.REACT_APP_API_URL}/entity/BeerStyle/list`,
    params: {sort: [['name', 'ASC']]}
  });
  const { data: stylesData } = useAPI<{items: BeerStyle[]}>(stylesRequest);
  const styles = stylesData ? stylesData.items.map(v => ({value: v.id, label: v.name})) : [];

  // useEffect(() => {
  //   reset({...getValues()}, {keepDefaultValues: true});
  // }, [styles, breweries]);

  useEffect(() => {
    console.log('changed req', saveRequest);
  }, [saveRequest]);

  useEffect(() => {
    console.log('changed saved', savedData);
    if (savedData !== null) {
      saved(savedData);
    }
  }, [savedData, saved]);

  const removeImage = (event: any) => {
    reset({...getValues(), image: null}, {keepDefaultValues: false});
    setImagePreview(null);
    setLoadedImage(null);
  }

  const uploadImage = (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const imageData = new FormData();
    imageData.append('image', event.target.files[0]);
    event.target.value = null;
    Axios.post(`${process.env.REACT_APP_API_URL}/file/uploadTestedImage`, imageData).then(
      res => {
        if (res.data) {
          setLoadedImage(res.data);
          setImagePreview(res.data.url);
        }
      },
      rej => { }
    );
  }

  const onSubmit: SubmitHandler<BeerFormInput> = async (data: BeerFormInput) => {
    console.log(data);

    let brewery;
    if (data.breweryId === -1) {
      const name = data.breweryName;
      const country = data.breweryCountry;
      if (!name || !country) return;
      brewery = await Axios.post(`${process.env.REACT_APP_API_URL}/entity/Brewery`, 
      {name, country}, {headers: { Authorization: 'Bearer ' + token}});
    }
    const cleanData = { 
      ...data, 
      imageId: loadedImage ? loadedImage.id : null,
      breweryId: brewery ? brewery : data.breweryId
    };
    if (beerData && beerData.id) {
      setSaveRequest({
        method: 'patch',
        path: `${process.env.REACT_APP_API_URL}/beer/tested/${beerData.id}`,
        body: cleanData
      });
    } else {
      setSaveRequest({
        method: 'post',
        path: `${process.env.REACT_APP_API_URL}/beer/tested`,
        body: cleanData
      });
    }
  }

  const breweryId = Number(watch('breweryId'));
  
  return (
    <form className='max-w-[960px] mx-auto px-[30px]'>
      <div className='flex flex-wrap justify-between flex-row gap-x-10 mt-10'>
        <div className='basis-full lg:basis-auto flex flex-col items-center lg:items-start gap-y-3 mb-4'>
          {imagePreview ? <img className='h-[300px] w-[300px]' alt='beer-label'
            src={`${imagePreview}`}
          /> : <div className='h-[300px] w-[300px] bg-medium-blue flex items-center justify-center'>
            <img className='h-[150px]' src={Logo} alt='no-label' />
          </div>}
          <div className='flex'>
            <label htmlFor="file-upload" className='rounded border border-light-blue text-light-blue hover:text-white hover:bg-light-blue cursor-pointer h-[36px] px-4 mr-4 pt-[5px]'>Upload</label>
            <input 
              id="file-upload" className='hidden' type="file"
              {...register("image"
                // {
                //   validate: {
                //     lessThan10MB: (files) => files[0]?.size < 30000 || "Max 30kb"
                //     // acceptedFormats: (files) =>
                //     //   ["image/jpeg", "image/png", "image/gif"].includes(
                //     //     files[0]?.type
                //     //   ) || "Only PNG, JPEG e GIF"
                //   }
                // }
              )}
              onChange={uploadImage}
              accept="image/png, image/jpeg"
            />
            {loadedImage ? <div>
              <button className='rounded border border-red-700 text-red-700 hover:text-white hover:bg-red-700 cursor-pointer h-[36px] px-4 mr-4' onClick={removeImage}>Remove</button>
            </div> : null}
          </div>
        </div>
        <div className='flex flex-grow flex-col gap-y-4 mb-4'>
          <div>
            <label className='px-2'>Name</label>
            <input className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 border w-full rounded h-[36px] px-3 text-sm' 
            {...register('name', { required: true })} />
          </div>
          <div className='grid grid-cols-2 gap-x-3'>
            <div>
              <label className='px-2'>ABV</label>
              <input type='number' className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full border rounded h-[36px] px-3 text-sm' {...register('abv')} />
            </div>
            <div>
              <label className='px-2'>IBU</label>
              <input type='number' className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full border rounded h-[36px] px-3 text-sm' {...register('ibu')} />
            </div>
          </div>
          <div>
            <label className='px-2'>Style</label>
            {/* <select className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full bg-white border rounded h-[36px] px-3' 
            {...register('styleId', { required: true })}>
              {styles && styles.length > 0 ? styles.map((style: BeerStyle, index: number) => <option key={'style-' + index} value={style.id}>{style.name}</option>) : null}
            </select> */}
            <Controller
              control={control}
              defaultValue={undefined}
              name='styleId'
              render={({ field }) => (
                    <Select
                        options={styles}
                        value={styles.find(c => c.value === field.value)}
                        onChange={val => field.onChange(val?.value)}
                    />
                )} />
            {/* <Select className='basis-28' 
                styles={selectStyles}
                // onChange={(selected) => handleFormChange([{field: 'rating', value: selected?.value}])}
                options={styles}
                {...register('styleId', { required: true })} /> */}
          </div>
          <div>
            <label className='px-2'>Brewery</label>
            <Controller
              control={control}
              defaultValue={undefined}
              name='breweryId'
              render={({ field }) => (
                    <Select
                        options={breweries}
                        value={breweries.find(c => c.value === field.value)}
                        onChange={val => field.onChange(val?.value)}
                    />
                )} />
            {/* <select className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full bg-white border rounded h-[36px] px-3' 
            {...register('breweryId', { required: true })}>
              <option key='custom-brewery' value={-1}>Add new brewery</option>
              {breweries && breweries.length > 0 ? breweries.map((brewery: Brewery, index: number) => <option key={'brewery-' + index} value={brewery.id}>{brewery.name}</option>) : null}
            </select> */}
          </div>
          <div className='w-full sm:flex gap-3 overflow-hidden transition-all ease-out duration-200' style={{maxHeight: breweryId === -1 ? '500px' : '0px'}}>
            <input className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full border rounded h-[36px] px-3 text-sm' placeholder={'New brewery name'} {...register('breweryName')} />
            <div>
              <select {...register('breweryCountry')} className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 w-full mt-2 sm:mt-0 sm:w-[210px] border bg-white rounded h-[36px] px-3'>
                  {countryCodes.map(item => <option key={'country-' + item.code} value={item.code}>{item.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className='basis-full flex flex-col gap-y-3 mb-4'>
          <label>Description</label>
          <textarea className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 border rounded p-3 text-sm' rows={5}  {...register('description')}></textarea>
        </div>
        <div className='flex flex-col px-2 gap-y-3 mb-4'>
          <label>Rating</label>
          <Controller
            name="rating"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => <Rating 
              onChange={onChange}
              value={value}
            ></Rating>}
          />
        </div>
      </div>
      <div className='flex justify-center mt-6 pb-6'>
        <BeerButton text={beerData && beerData.id ? 'SAVE' : 'CREATE'} onClick={handleSubmit(onSubmit)} />
      </div>
    </form>
  );
}

export default TestedBeerForm;
