import React from 'react';
import PageHeader from '../components/PageHeader';
import UserProfileForm from '../components/forms/UserProfileForm';

const UserProfile = () => {
    return (
        <div className='container pt-[50px] max-w-6xl'>
            <PageHeader title='My profile' />
            <UserProfileForm />
        </div>
    );
}

export default UserProfile;
