import React, { useEffect, useState } from 'react';
import TestedBeerForm from '../components/forms/TestedBeerForm';
import { useHistory, useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import Axios from 'axios';
import { TestedBeerInterface } from '../models/TestedBeerModel';
import { ApiRequestProps, useAPI } from '../hooks/useAPI';

const EditTestedBeer = () => {
    const router = useHistory();
    const { id } = useParams<{id: string}>();
    const [request, setRequest] = useState<ApiRequestProps | null>(null);
    const {data: beerData} = useAPI<TestedBeerInterface>(request);

    useEffect(() => {
        setRequest({method: 'get', path: `${process.env.REACT_APP_API_URL}/beer/tested/${id}`, params: {'include': ['FileReference']}});
    }, [id]);

    const saved = () => {
        router.push('/my-tested');
    }

    return (
        <div className='container pt-[50px] max-w-6xl'>
            <PageHeader title='Edit tested beer' />
            {beerData ? <TestedBeerForm saved={saved} beerData={beerData} /> : null}
        </div>
    );
}

export default EditTestedBeer;
