import { FunctionComponent } from "react";
import Breweries from "../pages/Breweries";
import Styles from "../pages/Styles";
import UserTestedBeers from "../pages/UserTestedBeers";
import TestedBeers from "../pages/TestedBeers";
import NewTestedBeer from "../pages/NewTestedBeer";
import EditTestedBeer from "../pages/EditTestedBeer";
import UserProfile from "../pages/UserProfile";
import MyTestedBeers from "../pages/MyTestedBeers";

export const routes: {
  path: string,
  name: string,
  icon: string,
  component: FunctionComponent<any>,
  layout: string,
  private: boolean
}[] = [
    {
      path: "/breweries",
      name: "Breweries",
      icon: "",
      component: Breweries,
      layout: "",
      private: false
    },
    {
      path: "/styles",
      name: "Styles",
      icon: "",
      component: Styles,
      layout: "",
      private: false
    },
    {
      path: "/my-tested/new",
      name: "New review",
      icon: "",
      component: NewTestedBeer,
      layout: "",
      private: true
    },
    {
      path: "/my-tested/edit/:id",
      name: "Edit review",
      icon: "",
      component: EditTestedBeer,
      layout: "",
      private: true
    },
    {
      path: "/my-tested",
      name: "My reviews",
      icon: "",
      component: MyTestedBeers,
      layout: "",
      private: true
    },
    {
      path: "/beers/user/:id",
      name: "User reviews",
      icon: "",
      component: UserTestedBeers,
      layout: "",
      private: false
    },
    {
      path: "/beers",
      name: "All reviews",
      icon: "",
      component: TestedBeers,
      layout: "",
      private: false
    },
    {
      path: "/account/profile",
      name: "Account profile",
      icon: "",
      component: UserProfile,
      layout: "",
      private: true
    },
    {
      path: "/",
      name: "Beers",
      icon: "",
      component: TestedBeers,
      layout: "",
      private: false
    },
  ];

export const accountRoutes = [];
