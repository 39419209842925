import React from 'react';
import { Brewery } from '../models/Brewery.model';
import { countryMap } from '../data/countries';
import VisibilityOff from '../assets/visibility_off.svg';

const BreweryCard = ({breweryData}: {breweryData: Brewery}) => {
    const countriesMap = countryMap;

    return (
        <div className={`w-[150px] bg-white rounded overflow-hidden 
        relative shadow-gray-300 shadow-lg text-black-blue`}>
            <div className='w-full h-[150px] flex justify-center'>
                {breweryData.logo ? <img className='max-w-full max-h-[150px]' src={breweryData.logo?.url} /> :
                <div className="h-full w-full bg-dark-blue bg-no-repeat bg-center bg-opacity-40 bg-[length:80px] bg-[url('../public/brewery.svg')]"></div>}
            </div>
            <div className='py-2 px-2 bg-medium-blue h-full'>
                <div className='text-white text-lg text-center leading-[1.15] mb-1'>{breweryData.name}</div>
                <div className='text-center text-sm text-gray-300 leading-[1]'>{countriesMap[breweryData.country]}</div>
            </div>
            {breweryData.moderated ? null : <div className={`absolute top-0 right-[15px] w-7 h-8
            bg-red-600 opacity-80 flex justify-center items-center rounded-b-md`}
            data-tooltip-id="unmoderated-tip" data-tooltip-variant='light'
            data-tooltip-html="This brewery is unmoderated, it is visible only to you.<br>We will review it as soon as we can.">
                <img className='w-4' src={VisibilityOff} />
            </div>}
        </div>
    );
}

export default BreweryCard;
