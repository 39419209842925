import React, { useEffect, useState } from 'react';
import { ApiRequestProps, useAPI } from '../hooks/useAPI';
import { Brewery } from '../models/Brewery.model';
import { BeerStyle } from '../models/BeerStyle.model';
import Select from 'react-select';
import Star from '../assets/star.svg';

export interface SearchData {
    name: string,
    breweryId: number,
    brewery: any,
    styleId: number,
    style: any,
    rating: number
}

const BeerSearch = ({searchChanged}: {searchChanged: Function}) => {
    const [searchForm, setSearchForm] = useState<SearchData>({
        name: '',
        breweryId: -1,
        brewery: null,
        styleId: -1,
        style: null,
        rating: 1
    });
    const [breweriesRequest] = useState<ApiRequestProps>({ 
        method: 'get', 
        path: '/entity/Brewery/list' 
    });
    const [stylesRequest] = useState<ApiRequestProps>({ 
        method: 'get', 
        path: '/entity/BeerStyle/list' 
    });
    const {data} = useAPI<{items: Brewery[]}>(breweriesRequest);
    const breweries = data?.items ? [{value: -1, label: 'All'}, ...data.items.map(v => ({value: v.id, label: v.name}))] : [];
    const {data: stylesData} = useAPI<{items: BeerStyle[]}>(stylesRequest);
    const styles = stylesData?.items ? [{value: -1, label: 'All'}, ...stylesData.items.map(v => ({value: v.id, label: v.name}))] : [];

    const handleFormChange = (changes: {field: string, value: any}[]) => {
        const newForm:any = {...searchForm};
        for (const change of changes) {
            newForm[change.field] = change.value;
        }
        setSearchForm(newForm);
        const outputData = {
            name: newForm.name,
            breweryId: newForm.breweryId,
            styleId: newForm.styleId,
            rating: newForm.rating
        };
        searchChanged(outputData);
        console.log('form changed');
    }

    const selectStyles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            border: 0,
            boxShadow: state.isFocused ? '0 0 0 1px #466077' : 'none',
            minHeight: '36px'
        })
    }

    const ratingOptions = [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
        {value: 4, label: '4'},
        {value: 5, label: '5'},
    ];

    const formatOptionLabel = ({ value, label }: {value: number, label: string}) => (
        <div style={{ display: "flex", alignItems: 'center' }}>
            <img src={Star} width='18px' style={{marginRight: '5px'}} />
            <div>{label + (value === 5 ? '' : '+')}</div>
        </div>
    );

    return (
        <div className='w-full flex justify-center gap-1 z-30 relative'>
            <input className='basis-1/4 rounded h-[36px] px-3 text-sm border border-transparent focus:border-light-blue outline-none' 
                placeholder='Name'
                value={searchForm.name} onChange={
                (event) => handleFormChange([{field: 'name', value: event.target.value}])
            } />
            {breweries ? <Select className='basis-1/4 focus:border-light-blue' 
                styles={selectStyles}
                value={searchForm.brewery} onChange={
                (selected) => handleFormChange([{field: 'breweryId', value: selected?.value}, {field: 'brewery', value: selected}])
            } options={breweries} /> : null }
            {styles ? <Select className='basis-1/4' 
                styles={selectStyles} value={searchForm.style} onChange={
                (selected) => handleFormChange([{field: 'styleId', value: selected?.value}, {field: 'style', value: selected} ])
            } options={styles} /> : null}
            <Select className='basis-28' 
                styles={selectStyles} value={ratingOptions[searchForm.rating - 1]} 
                formatOptionLabel={formatOptionLabel}
                onChange={(selected) => handleFormChange([{field: 'rating', value: selected?.value}])
            } options={ratingOptions} />
        </div>
    );
}

export default BeerSearch;
