import React, { useState, useContext, useEffect } from 'react';
import ModalListContext from '../context/modalListContext';
import { BeerStyle } from '../models/BeerStyle.model';
import BeerStyleForm from '../components/forms/BeerStyleForm';
import BeerButton, { ButtonStyle } from '../components/BeerButton';
import { ApiRequestProps, useAPI } from '../hooks/useAPI';
import StyleCard from '../components/StyleCard';
import ListFilters, { FilterType } from '../components/listUtils/ListFilters';
import Pagination from '../components/listUtils/Pagination';

const initFilters = {
    name: {defaultValue: '', placeholder: 'Name', type: FilterType.STRING},
}

const Styles = () => {
    const perPage = 12;
    const {addModal, closeModal} = useContext<any>(ModalListContext);
    const [request, setRequest] = useState<ApiRequestProps>({
        method: 'get', 
        path: `${process.env.REACT_APP_API_URL}/entity/BeerStyle/list`,
        params: {sort: [['id', 'DESC']], pagination: {page: 0, perPage: perPage}}
    });
    const {data} = useAPI<{items: BeerStyle[], totalItems: number}>(request);
    const styles = data?.items ? data.items.map(v => ({...v, shortDescription: v.description ? v.description.slice(0, 160) : ''})) : [];
    const totalPages = data ? Math.ceil(data.totalItems / perPage) : 0;

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [data])
    
    const refresh = () => {
        setRequest({...request});
    }

    const openEditor = (style?: BeerStyle) => {
        if (style) {
            addModal({
                content: <StyleCard styleData={style} fullView={true}
                ></StyleCard>, 
                size: {
                    maxWidth: 300,
                    maxHeight: 'fit-content',
                    padding: 0
                }
            });
        } else {
            addModal({
                content: <BeerStyleForm 
                    created={() => {
                        closeModal();
                        refresh();
                    }}
                ></BeerStyleForm>, 
                size: {
                    maxWidth: 320,
                    maxHeight: 'fit-content'
                }
            });
        }
    }

    const pageChanged = (pageNum: number) => {
        if (!request) return;
        if (pageNum !== request.params.pagination.page) {
            setRequest({...request, params: {...request.params, pagination: {...request.params.pagination, page: pageNum}}});
        }
    }

    const filtersChanged = (filters: any) => {
        const filtersQuery: any = { ...filters };
        if (filtersQuery.name.trim() === '') {
            delete filtersQuery.name;
        } else {
            filtersQuery.name = {$like: `%${filtersQuery.name.trim()}%`}
        }
        setRequest({
          ...request,
          params: {
            ...request.params,
            pagination: {page: 0, perPage: perPage},
            where: filtersQuery
          }
        })
    }

    return (
        <div className='container pt-[50px] mx-auto'>
            <div className='text-3xl mb-8 font-bold text-gray-800 relative border-b-2 border-b-dark-blue mx-6'>
                BEER STYLES
            </div>

            <div className='flex w-full'>
                <div className='basis-full md:basis-1/4'>
                    <ListFilters filters={initFilters} changed={filtersChanged} />
                    <div className='pl-6 mt-6'><BeerButton style={ButtonStyle.OUTLINE} text='Add style' onClick={openEditor} /></div>
                </div>
                <div className='basis-full md:basis-3/4'>
                    <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-10 px-6 pb-10'>
                        {styles ? styles.map((style: BeerStyle, index: number) => <div className='flex justify-center' key={'style-' + index}>
                            <StyleCard styleData={style} onClick={() => openEditor(style)}></StyleCard>
                        </div>) : null}
                    </div>
                    <Pagination className='pb-10' currentPage={request ? request.params.pagination.page + 1 : 1}
                    totalPages={totalPages} changed={(num: number) => pageChanged(num - 1)} />
                </div>
            </div>
        </div>
    );
}

export default Styles;
