import { useCallback, useContext, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { User } from "../models/User.model";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import Axios from "axios";

export interface UserJwtPayload extends JwtPayload {
  data: User
}

export const useAuth = () => {
  const { auth, token } = useContext(AuthContext);
  const { getItem, setItem, removeItem } = useLocalStorage();

  useEffect(() => {
    const userLS = getItem('user');
    const tokenLS = getItem('token');
    if (!token && userLS && tokenLS) {
      try {
        const decodedToken = jwtDecode<UserJwtPayload>(tokenLS);
        if (decodedToken.exp && (decodedToken.exp < moment().unix())) {
          logout();
        } else {
          const timeBuffer = 60 * 60 * 2; // two hours;
          if (decodedToken.exp && ((moment().unix() + timeBuffer) > decodedToken.exp)) {
            refreshToken(tokenLS);
          } else {
            const userData = decodedToken.data;
            auth({ user: userData, token: tokenLS });
          }
        }
      } catch (err) {
        logout();
      }
    } else if (!token) {
      logout();
    }
  }, []);

  const refreshToken = async (currentToken: string) => {
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/auth/refresh`, {}, 
        {headers: {'Authorization': `Bearer ${currentToken}`}}
      );
      const authToken = data.token;
      login(authToken);
    } catch(err) {
      logout();
    }
  }

  const logout = useCallback(() => {
    removeItem('token');
    removeItem('user');
    auth({ user: null, token: null });
  }, []);

  const login = (authToken: any) => {
    const decodedToken = jwtDecode<UserJwtPayload>(authToken);
    const userData = decodedToken.data;
    setItem('token', authToken);
    setItem('user', JSON.stringify(userData));
    auth({ user: userData, token: authToken });
  };

  return { login, logout, refreshToken };
};
