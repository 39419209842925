import React, { useContext } from 'react';
import BeersContext from '../../context/beersContext';
import { TestedBeerInterface } from '../../models/TestedBeerModel';
import moment from 'moment';

const BeerDescription = () => {
    const beer = useContext<TestedBeerInterface | null>(BeersContext);

    return beer ? (
        <div className='w-full whitespace-pre-line px-8 py-4 text-sm h-[120px] flex flex-col justify-between text-justify mb-2'>
            <span className='mb-1'>{beer.description.length > 130 ? beer.description.slice(0, 130) + '...' : beer.description}</span>
            <span className='opacity-60'>{beer.createdAt ? moment(beer.createdAt).format('DD.MM.YYYY') : ''}</span>
        </div>
    ) : null;
}

export default BeerDescription;
