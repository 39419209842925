import Axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Brewery } from '../../models/Brewery.model';
import BeerButton from '../BeerButton';
import { countryCodes } from '../../data/countries';
import User from '../../assets/account.svg';
import Select from 'react-select';
import { ApiRequestProps, useAPI } from '../../hooks/useAPI';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthContext } from '../../context/authContext';

const countries = countryCodes.map(v => ({ value: v.code, label: v.name }));

interface UserProfileFormInput {
    name: string,
    image: any | null,
    country?: string,
    email: string
}

const UserProfileForm = () => {
    const { user, token } = useContext(AuthContext);
    // const [loadedImage, setLoadedImage] = useState<{ id: number, url: string } | null>(user && user?.image ? user.image : null);

    const [saveRequest, setSaveRequest] = useState<ApiRequestProps | null>(null);
    const { data: savedData } = useAPI(saveRequest);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch,
        getValues
    } = useForm<UserProfileFormInput>({
        defaultValues: useMemo(() => {
            return {
                name: user && user.name ? user.name : '',
                email: user && user.email ? user.email : '',
                image: user && user.image ? user.image : undefined,
            };
        }, [user])
    });

    useEffect(() => {
        if (user) {
            reset({
                name: user.name ? user.name : '',
                email: user.email ? user.email : '',
                image: user.image ? user.image : undefined,
            })
        }
    }, [user])

    const removeImage = (event: any) => {
        reset({ ...getValues(), image: null }, { keepDefaultValues: false });
        // setLoadedImage(null);
    }

    const uploadImage = (event: any) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        const imageData = new FormData();
        imageData.append('image', event.target.files[0]);
        event.target.value = null;
        Axios.post(`${process.env.REACT_APP_API_URL}/file/upload/user-image`, imageData, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(
            res => {
                if (res.data) {
                    reset({...getValues(), image: {...res.data}});
                    // setLoadedImage(res.data);
                }
            },
            rej => { }
        );
    }

    const onSubmit: SubmitHandler<UserProfileFormInput> = async (data: UserProfileFormInput) => {
        console.log(data);
        if (!user) return;
        const cleanData = {
            ...data,
            // imageId: loadedImage ? loadedImage.id : null
            imageId: data.image && data.image.id ? data.image.id : null
        };
        delete cleanData.image;
        setSaveRequest({
            method: 'patch',
            path: `${process.env.REACT_APP_API_URL}/entity/User/${user.id}`,
            body: cleanData
        });
    }

    const imageUrl = getValues().image?.url;

    return (
        <form className='max-w-[960px] mx-auto px-[30px]'>
            <div className='flex flex-wrap justify-between flex-row gap-x-10 mt-10'>
                <div className='basis-full lg:basis-auto flex flex-col items-center lg:items-start gap-y-3 mb-4'>
                    {imageUrl ? <img className='h-[300px] w-[300px]' alt='beer-label'
                        src={`${imageUrl}`}
                    /> : <div className='h-[300px] w-[300px] bg-medium-blue flex items-center justify-center'>
                        <img className='h-[150px]' src={User} alt='no-label' />
                    </div>}
                    <div className='flex'>
                        <label htmlFor="file-upload" className='rounded border border-light-blue text-light-blue hover:text-white hover:bg-light-blue cursor-pointer h-[36px] px-4 mr-4 pt-[5px]'>Upload</label>
                        <input
                            id="file-upload" className='hidden' type="file"
                            {...register("image"
                                // {
                                //   validate: {
                                //     lessThan10MB: (files) => files[0]?.size < 30000 || "Max 30kb"
                                //     // acceptedFormats: (files) =>
                                //     //   ["image/jpeg", "image/png", "image/gif"].includes(
                                //     //     files[0]?.type
                                //     //   ) || "Only PNG, JPEG e GIF"
                                //   }
                                // }
                            )}
                            onChange={uploadImage}
                            accept="image/png, image/jpeg"
                        />
                        {imageUrl ? <div>
                            <button className='rounded border border-red-700 text-red-700 hover:text-white hover:bg-red-700 cursor-pointer h-[36px] px-4 mr-4' onClick={removeImage}>Remove</button>
                        </div> : null}
                    </div>
                </div>
                <div className='flex flex-grow flex-col gap-y-4 mb-4'>
                    <div>
                        <label className='px-2'>Name</label>
                        <input className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 border w-full rounded h-[36px] px-3 text-sm'
                            {...register('name', { required: true })} />
                    </div>
                    <div>
                        <label className='px-2'>Email</label>
                        <input className='focus:ring-0 focus:outline-none focus:shadow-outline focus:shadow-beer-blue-300 focus:border-beer-blue-300 border w-full rounded h-[36px] px-3 text-sm'
                            {...register('email', { required: true })} />
                    </div>
                </div>
            </div>
            <div className='flex justify-center mt-6 pb-6'>
                <BeerButton text='SAVE' onClick={handleSubmit(onSubmit)} />
            </div>
        </form>
    );
}

export default UserProfileForm;
