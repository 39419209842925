import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './ListFilters.css';

export enum FilterType {
    SELECT,
    STRING,
    NUMBER
}

export interface Filters {
    [name: string]: Filter;
}

export interface Filter {
    type: FilterType,
    options?: {value: any, label: string}[],
    placeholder?: string,
    defaultValue: any
}

export interface FilterItem extends Filter {
    name: string
}

const ListFilters = ({filters, changed}: {filters: Filters, changed: Function}) => {
    const [filtersForm, setFiltersForm] = useState<any>({});
    const [filtersList, setFiltersList] = useState<FilterItem[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>({});

    useEffect(() => {
        console.log('filters changed', filters);
        const newForm:any = {};
        const newFiltersList = [];
        const newSelectedOptions: any = {};
        for (const name in filters) {
            newFiltersList.push({...filters[name], name});
            newForm[name] = typeof filters[name].defaultValue !== undefined ? filters[name].defaultValue : null;
            if (filters[name].type === FilterType.SELECT) {
                const option = filters[name].options ? filters[name].options?.find(v => v.value === newForm[name]) : null;
                newSelectedOptions[name] = option;
            }
        }
        setFiltersForm(newForm);
        setFiltersList(newFiltersList);
        setSelectedOptions(newSelectedOptions);
        console.log(filtersList)
    }, [filters]);

    const handleFormChange = (changes: {field: string, value: any, option?: any}[]) => {
        const newForm:any = {...filtersForm};
        const newSelectedOptions:any = {...selectedOptions};
        for (const change of changes) {
            newForm[change.field] = change.value;
            if (filters[change.field].type === FilterType.SELECT) newSelectedOptions[change.field] = change.option;
        }
        setFiltersForm(newForm);
        setSelectedOptions(newSelectedOptions);
        changed(newForm);
        console.log(newForm);
    }

    const selectStyles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            background: 'transparent',
            border: 0,
            boxShadow: 'none',
            minHeight: '36px'
        }),
        singleValue: (baseStyles: any, state: any) => ({
            ...baseStyles,
            paddingLeft: 0,
            color: '#466077'
        }),
        valueContainer: (baseStyles: any, state: any) => ({
            ...baseStyles,
            paddingLeft: 0
        }),
        indicatorSeparator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            color: '#18222c',
            '&:hover': {color: '#18222c'}
        })
    }

    const renderFilter = (filter: FilterItem) => {
        switch(filter.type) {
            case FilterType.STRING:
                return <input className={`w-full h-[36px] text-light-blue border 
                border-transparent outline-none bg-transparent focus:text-dark-blue`}
                    {...(filter.placeholder ? {placeholder: filter.placeholder} : {})}
                    value={filtersForm[filter.name]} onChange={
                    (event) => handleFormChange([{field: filter.name, value: event.target.value}])
                } />
            case FilterType.SELECT:
                return <Select className='w-full' 
                    classNamePrefix={'select-filter'}
                    {...(filter.defaultValue ? {defaultValue: filter.defaultValue} : {})}
                    styles={selectStyles} value={selectedOptions[filter.name]} 
                    // formatOptionLabel={formatOptionLabel}
                    onChange={(selected) => handleFormChange([{field: filter.name, value: selected?.value, option: selected}])
                } options={filter.options ? filter.options : []} />
        }
    }

    return (
        <div className='w-full z-30 relative pl-6'>
            {filtersList.map(filter => <div key={'filter-' + filter.name} className='mb-3 relative after:w-full after:absolute after:h-[1px] after:bottom-0 after:bg-dark-blue after:block focus-within:after:h-[2px] after:transition-all after:ease-in after:duration-[50]'>
                {renderFilter(filter)}
            </div>)}
        </div>
    );
}

export default ListFilters;
