import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import MainLayout from "./components/layout/MainLayout"
import ModalContainer from './components/Modal/ModalContainer';
import ModalListContext from './context/modalListContext';
import { AuthContext } from './context/authContext';
import { User } from './models/User.model';
import Modal from 'react-modal';
import Close from './assets/close-dark.svg';
import { Tooltip } from 'react-tooltip';

const history = createBrowserHistory();

function App() {
  const [modals, setModals] = useState<any[]>([]);
  const [currentModal, setCurrentModal] = useState(null);
  const [modalSize, setModalSize] = useState({});
  const [modalOpened, setModalOpened] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [userData, setUserData] = useState<{ user: User | null, token: string | null }>({ user: null, token: null });

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const closeModal = () => {
    setModalOpened(false);
  }

  const renderModalContent = () => {
    return currentModal;
  }

  return (
    <AuthContext.Provider value={{ 
      user: userData.user, 
      token: userData.token, 
      isLoading: authLoading,
      auth: (data) => {
        console.log(data);
        setUserData(data);
        setAuthLoading(false);
      }
    }}>
      <Modal
        isOpen={modalOpened}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            zIndex: 40,
            background: '#00000050'
          },
          content: {
            padding: '15px',
            border: 'none',
            borderRadius: '0',
            zIndex: 40,
            left: 'calc(50% - 200px)',
            ...modalSize,
          }
        }}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='absolute z-40' style={{right: '15px', top: '15px'}}>
          <img src={Close} height="20px" width="20px" />
        </button>
        {renderModalContent()}
      </Modal>
      <ModalListContext.Provider value={{
        modals,
        closeModal: (index: any) => { setModalOpened(false) },
        addModal: ({ content, size={} }: any) => {
          setCurrentModal(content);
          setModalSize(size);
          setModalOpened(true);
        }
      }}>
        <Router history={history}>
          <Switch>
            <Route path="/">
              <MainLayout></MainLayout>
            </Route>
            <Redirect to="/beers" />
          </Switch>
        </Router>
        <Tooltip id="unmoderated-tip" className='opaque-tooltip'/>
      </ModalListContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
