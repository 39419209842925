import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BeerStyle } from '../../models/BeerStyle.model';
import BeerButton from '../BeerButton';
import { ApiRequestProps, useAPI } from '../../hooks/useAPI';

const BeerStyleForm = ({styleData, created}: {styleData?: BeerStyle, created: Function}) => {
    const [saveRequest, setSaveRequest] = useState<ApiRequestProps | null>(null);
    const { data: savedData } = useAPI(saveRequest);
  
    const [formData, setFormData] = useState({
        name: styleData && styleData.name ? styleData.name : '',
        minABV: styleData && styleData.minABV ? styleData.minABV : '',
        maxABV: styleData && styleData.maxABV ? styleData.maxABV : '',
        minIBU: styleData && styleData.minIBU ? styleData.minIBU : '',
        maxIBU: styleData && styleData.maxIBU ? styleData.maxIBU : '',
        description: styleData && styleData.description ? styleData.description : ''
    });

    useEffect(() => {
        if (savedData !== null) {
            created(savedData);
        }
    }, [savedData, created]);

    const save = () => {
        const cleanData = {...formData};
        if (styleData && styleData.id) {
            setSaveRequest({
                method: 'patch',
                path: `${process.env.REACT_APP_API_URL}/entity/BeerStyle/${styleData.id}`,
                body: cleanData
            });
        } else {
            setSaveRequest({
                method: 'post',
                path: `${process.env.REACT_APP_API_URL}/entity/BeerStyle`,
                body: cleanData
            });
        }
    }

    return (
        <div className='pt-4 px-3'>
            <div className='text-xl mb-2'>Create new beer style</div>
            <div className='text-[.8rem] text-gray-500 leading-[1.2] text-justify mb-2'>
                Created style will be reviewed and added to public display after moderation process. Till then it will be visible only for you.
            </div>
            <div className='flex flex-col gap-y-1 text-sm mb-3'>
                <label>Name</label>
                <input className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.name} onChange={
                    (event) => {
                        const value = event.target.value;
                        setFormData(current => ({...current, name: value}));
                    }
                } />
            </div>
            <div className='grid grid-cols-2 gap-1 mt-2 text-sm'>
                <div className='flex flex-col gap-y-1 mb-3'>
                    <label>min ABV</label>
                    <input type='number' className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.minABV} onChange={
                        (event) => {
                            const value = event.target.value;
                            setFormData(current => ({...current, minABV: Number(value)}));
                        }
                    } />
                </div>
                <div className='flex flex-col gap-y-1 mb-3'>
                    <label>max ABV</label>
                    <input type='number' className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.maxABV} onChange={
                        (event) => {
                            const value = event.target.value;
                            setFormData(current => ({...current, maxABV: Number(value)}));
                        }
                    } />
                </div>
                <div className='flex flex-col gap-y-1 mb-3'>
                    <label>min IBU</label>
                    <input type='number' className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.minIBU} onChange={
                        (event) => {
                            const value = event.target.value;
                            setFormData(current => ({...current, minIBU: Number(value)}));
                        }
                    } />
                </div>
                <div className='flex flex-col gap-y-1 mb-3'>
                    <label>max IBU</label>
                    <input type='number' className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.maxIBU} onChange={
                        (event) => {
                            const value = event.target.value;
                            setFormData(current => ({...current, maxIBU: Number(value)}));
                        }
                    } />
                </div>
            </div>
            <div className='flex flex-col gap-y-1 text-sm mb-3'>
                <label>Name</label>
                <textarea className='border rounded px-3 text-sm focus:border-black' value={formData.description} onChange={
                    (event) => {
                        const value = event.target.value;
                        setFormData(current => ({...current, description: value}));
                    }
                } rows={5}></textarea>
            </div>
            <div className='flex justify-center mt-4'>
                <BeerButton text='CREATE' onClick={save} />
            </div>
        </div>
    );
}

export default BeerStyleForm;
