import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Brewery } from '../../models/Brewery.model';
import BeerButton from '../BeerButton';
import { countryCodes } from '../../data/countries';
import Empty from '../../assets/no-photo.svg';
import Delete from '../../assets/delete.svg';
import Select from 'react-select';
import { ApiRequestProps, useAPI } from '../../hooks/useAPI';

const countries = countryCodes.map(v => ({value: v.code, label: v.name}));

const BreweryForm = ({ breweryData, created }: { breweryData?: Brewery, created: Function }) => {
    const [saveRequest, setSaveRequest] = useState<ApiRequestProps | null>(null);
    const { data: savedData } = useAPI(saveRequest);
    const [formData, setFormData] = useState({
        name: breweryData && breweryData.name ? breweryData.name : '',
        country: breweryData && breweryData.country ? breweryData.country : undefined,
        logoId: breweryData && breweryData.logoId ? breweryData.logoId : null,
        logo: breweryData && breweryData.logo ? breweryData.logo : null
    });
  
    useEffect(() => {
        if (savedData !== null) {
            created(savedData);
        }
    }, [savedData, created]);
    
    const create = () => {
        const cleanData = { ...formData, name: formData.name.trim(), logoId: formData.logo ? formData.logo.id : breweryData?.logoId };
        if (!cleanData.country || cleanData.name.length === 0) return;

        setSaveRequest({
            method: 'post',
            path: `${process.env.REACT_APP_API_URL}/entity/Brewery`,
            body: cleanData
        });
    }

    const removeImage = (event: any) => {
        setFormData((current) => ({ ...current, logo: null }));
    }

    const uploadImage = (event: any) => {
        if (!event.target.files || event.target.files.length === 0) {
          return;
        }
        const imageData = new FormData();
        imageData.append('image', event.target.files[0]);
        event.target.value = null;
        Axios.post(`${process.env.REACT_APP_API_URL}/file/uploadBreweryLogo`, imageData).then(
          res => {
            if (res.data) {
              setFormData((current) => ({ ...current, logo: res.data }));
            }
          },
          rej => { }
        );
    }

    const selectStyles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            background: 'transparent',
            // border: 0,
            borderColor: state.isFocused ? 'black' : '#e5e7eb',
            boxShadow: 'none',
            minHeight: '36px'
        }),
        // singleValue: (baseStyles: any, state: any) => ({
        //     ...baseStyles,
        //     paddingLeft: 0,
        //     color: '#466077'
        // }),
        // valueContainer: (baseStyles: any, state: any) => ({
        //     ...baseStyles,
        //     paddingLeft: 0
        // }),
        indicatorSeparator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            color: '#e5e7eb',
            '&:hover': {color: '#e5e7eb'}
        })
    };

    return (
        <div className='pt-4 px-3'>
            <div className='text-xl mb-2'>Create new brewery</div>
            <div className='text-[.8rem] text-gray-500 leading-[1.2] text-justify'>
                Created brewery will be reviewed and added to public display after moderation process. Till then it will be visible only for you.
            </div>
            <div className='mb-4 flex flex-row mt-4 gap-x-1'>
                {formData.logo ? <div className='flex justify-center items-center relative w-[80px] h-[80px]'>
                    <img className='w-full max-h-[80px]' alt='beer-label' src={`${formData.logo.url}`} />
                    <div onClick={removeImage} className={`absolute w-full h-full bg-[#00000050] 
                    hover:opacity-100 opacity-0 flex justify-center items-center 
                    ease-in transition-all cursor-pointer`}>
                        <img src={Delete} className='h-[40px]' />
                    </div>
                </div> : <div className='flex items-center justify-center bg-gray-200 w-[80px] h-[80px]'>
                    <img className='w-[40px]' src={Empty} alt='no-label' />
                </div>}
                <div className='flex justify-center pl-3'>
                    <label htmlFor="file-upload" className={`rounded border border-light-blue text-light-blue 
                        hover:text-white hover:bg-light-blue cursor-pointer h-[36px] block 
                        px-4 mr-4 pt-[5px]`
                    }>Upload</label>
                    <input id="file-upload" className='hidden' type="file" name="image" onChange={uploadImage} />
                </div>
            </div>
            <div className='flex flex-col text-sm mb-2'>
                <label>Brewery name</label>
                <input className='border rounded h-[36px] px-3 text-sm focus:border-black' value={formData.name} onChange={
                    (event) => {
                        const value = event.target.value;
                        setFormData(current => ({ ...current, name: value }));
                    }
                } />
            </div>
            <div className='flex flex-col text-sm'>
                <label>Country</label>
                <Select className='w-full'
                    defaultValue={null}
                    styles={selectStyles} 
                    value={countries.find(v => v.value === formData.country)}
                    onChange={(selected) => setFormData(current => ({ ...current, country: selected?.value }))}
                    options={countries} />
            </div>
            <div className='flex justify-center mt-4'>
                <BeerButton text='CREATE' onClick={create} />
            </div>
        </div>
    );
}

export default BreweryForm;
