import React, { useEffect, useState } from 'react';
import EmptyStar from '../assets/star_empty.svg';
import Star from '../assets/star.svg';

const Rating = ({value=0, onChange, immutable=false}: {value?: number, onChange?: Function, immutable?: boolean}) => {
    const [currentHover, setCurrentHover] = useState(value);

    const onHover = (index: number) => {
        if (immutable) return;
        setCurrentHover(index > value ? index : value);
    }

    const onUnhover = () => {
        if (immutable) return;
        setCurrentHover(value);
    }

    const selected = (value: number) => {
        if (immutable || !onChange) return;
        onChange(value)
    }

    useEffect(() => {
        setCurrentHover(value);
    }, [value]);

    return (
        <div className='flex w-[180px] justify-between'>
            {(new Array(5).fill(0).map((v, i) => i+1).map(
                star => <div 
                    className='h-[36px] w-[36px]' key={'star-' + star} 
                    style={{ 
                        backgroundImage: `url(${EmptyStar})`,
                        backgroundSize: '22px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                    onMouseEnter={() => onHover(star)}
                    onMouseLeave={() => onUnhover()}
                    onClick={() => selected(star)}
                >
                    <div 
                        style={{
                            backgroundImage: `url(${Star})`,
                            backgroundSize: '22px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            visibility: currentHover >= star ? 'visible' : 'hidden'
                        }} 
                        className='h-full w-full'
                    ></div>
                </div>
            ))}
        </div>
    );
}

export default Rating;
